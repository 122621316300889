<template>
    <footer class="mt-10 w-full rounded-xl py-4 text-center children:(mx-3)">
        <a aria-label="Arvrtise" href="https://arvrtise.com" target="_blank">
            <font-awesome-icon :icon="['fas', 'user']" />
            <span>Arvrtise</span>
        </a>
        <a href="https://ateam.arvrtise.com" target="_blank">
            <font-awesome-icon :icon="['fab', 'discord']" />
            <span>A-Team PPV</span>
        </a>
        <a href="https://tiktok.com/@arvrtise" target="_blank">
            <font-awesome-icon :icon="['fab', 'tiktok']" />
            <span>TikTok</span>
        </a>
        <a href="https://instagram.com/arvrtise" target="_blank">
            <font-awesome-icon :icon="['fab', 'instagram']" />
            <span>Instagram</span>
        </a>
        <a href="https://x.com/arvrtise" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter']" />
            <span>X</span>
        </a>
    </footer>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    components: {
        FontAwesomeIcon,
    },
    mounted() {
        // Fetching configuration data if required
        // this.fetchConfig();
    },
    methods: {
        // async fetchConfig() {
        //   try {
        //     const response = await fetch(this.apiUrl() + "/config");
        //     const config = await response.json();
        //     // Handle configuration data if necessary
        //   } catch (error) {
        //     console.error("Error fetching config:", error);
        //   }
        // },
    },
};
</script>

<style>
footer {
    @apply bg-light-900;
}
.dark footer {
    @apply bg-dark-800;
}
</style>
